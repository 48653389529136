/* eslint-disable */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
:root {
  height: 100%;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 220 13% 91%;
    --input: 214.3 31.8% 91.4%;

    --primary: 248 90% 66%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 0%;
    --foreground: 210 40% 98%;

    --muted: 0 0% 15%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 0 0% 0%;
    --popover-foreground: 210 40% 98%;

    --card: 0 0% 0%;
    --card-foreground: 210 40% 98%;

    --border: 0 0% 20%;
    --input: 0 0% 20%;

    --primary: 210 40% 98%;
    --primary-foreground: 0 0% 0%;

    --secondary: 0 0% 15%;
    --secondary-foreground: 210 40% 98%;

    --accent: 0 0% 15%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --ring: 0 0% 20%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground font-quicksand;
  }
}
.nav-container {
  display: flex;
  gap: 2rem;
}

.nav-item {
  cursor: pointer;
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0.5rem 0;
  z-index: 10;
}

.nav-item:hover .dropdown-menu {
  display: block;
}

.dropdown-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #374151;
  text-decoration: none;
}

.dropdown-link:hover {
  background-color: #f3f4f6;
}

.font-quicksand {
  font-family: 'Quicksand', sans-serif;
}

@layer components {
  .search-progress {
    @apply max-w-md mx-auto space-y-4;
  }

  .progress-step {
    @apply p-4 rounded-lg bg-gray-800 transition-all duration-300;
  }

  .progress-step.completed {
    @apply bg-gray-700;
  }

  .step-header {
    @apply flex justify-between items-center;
  }

  .step-name {
    @apply text-sm font-medium text-gray-300;
  }

  .step-status {
    @apply flex items-center;
  }

  .loading-circle {
    @apply w-5 h-5 border-2 border-blue-500 border-t-transparent rounded-full;
  }

  .check-icon {
    @apply w-5 h-5 text-green-500 fill-current;
  }

  .step-result {
    @apply mt-2 text-xs text-gray-400;
  }
}

.custom-avatar-box {
  border-radius: 0 !important;
  overflow: hidden;
  border: 1px solid #111827 !important;
  transform: scale(1.2) !important;
  position: relative !important;
}

.custom-avatar-box::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #111827;
  mix-blend-mode: color;
  transition: opacity 0.3s ease;
}

.custom-avatar-box:hover::after {
  opacity: 0;
}

/* Add these styles to your global CSS file */
:root {
  --shadow-color: 0deg 0% 0%;
  --shadow-elevation-low:
    0px 0.5px 0.6px hsl(var(--shadow-color) / 0.34),
    0px 0.9px 1px -1.2px hsl(var(--shadow-color) / 0.34),
    0px 2.1px 2.4px -2.5px hsl(var(--shadow-color) / 0.34);
  --shadow-elevation-medium:
    0px 0.5px 0.6px hsl(var(--shadow-color) / 0.36),
    0px 1.8px 2px -0.8px hsl(var(--shadow-color) / 0.36),
    0px 4.3px 4.8px -1.7px hsl(var(--shadow-color) / 0.36),
    0px 10.3px 11.5px -2.5px hsl(var(--shadow-color) / 0.36);
}

body {
  background-color: #121212;
  color: #ffffff;
}

.elevated {
  box-shadow: var(--shadow-elevation-medium);
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 8px;
}

/* Add this to your existing global styles */

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #4b5563 #1f2937;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #1f2937;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4b5563;
  border-radius: 4px;
  border: 2px solid #1f2937;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #6b7280;
}

html {
  scroll-behavior: smooth;
}

/* Add this to your global CSS file */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@supports (padding: max(0px)) {
  .safe-area-inset {
    padding-left: max(1rem, env(safe-area-inset-left));
    padding-right: max(1rem, env(safe-area-inset-right));
    padding-top: max(2rem, env(safe-area-inset-top));
    padding-bottom: max(2rem, env(safe-area-inset-bottom));
  }
}

@keyframes lightning {
  0%, 100% {
    background-position: 0% 50%;
    opacity: 0.8;
  }
  50% {
    background-position: 100% 50%;
    opacity: 1;
  }
}

.animate-lightning {
  animation: lightning 5s linear infinite;
}

@keyframes forward {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255,255,255,0);
  }
  100% {
    transform: scale(1.05);
    box-shadow: 0 0 50px 10px rgba(255,255,255,0.3);
  }
}

.animate-forward {
  animation: forward 0.5s ease-out forwards;
}

.glow-purple {
  text-shadow: 0 0 10px #8b5cf6, 0 0 20px #8b5cf6, 0 0 30px #8b5cf6;
}

.glow-red {
  text-shadow: 0 0 10px #B22234, 0 0 20px #B22234, 0 0 30px #B22234;
}

.glow-american-blue {
  text-shadow: 0 0 10px #002868, 0 0 20px #002868, 0 0 30px #002868;
}

.glow-palm-springs-sunset {
  text-shadow: 0 0 10px #FF6B6B, 0 0 20px #FF6B6B, 0 0 30px #FF6B6B;
}

@layer utilities {
  .shadow-glow {
    box-shadow: 0 0 15px 5px rgba(59, 130, 246, 0.5);
  }
  .shadow-glow-light {
    box-shadow: 0 0 10px 2px rgba(59, 130, 246, 0.3);
  }
  .shadow-glow-intense {
    box-shadow: 0 0 20px 8px rgba(59, 130, 246, 0.6);
  }
  .shadow-glow-medium {
    box-shadow: 0 0 15px 5px rgba(59, 130, 246, 0.4);
  }
}

@keyframes twinkle {
  0% { opacity: 0.7; }
  50% { opacity: 0.3; }
  100% { opacity: 0.7; }
}
@layer utilities {
  .perspective-1000 {
    perspective: 1000px;
  }
  
  .translate-z-12 { transform: translateZ(12px); }
  .translate-z-24 { transform: translateZ(24px); }
  .translate-z-36 { transform: translateZ(36px); }
  
  .pricing-container {
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }
}
@layer utilities {
  .bg-gradient-radial {
    background-image: radial-gradient(var(--tw-gradient-stops));
  }
  
  @keyframes pulse {
    0%, 100% { opacity: 0.5; }
    50% { opacity: 0.3; }
  }
  
  .animate-pulse {
    animation: pulse 4s ease-pulse-ease infinite;
  }
  
  @keyframes float {
    0%, 100% { transform: translateY(0px); }
    50% { transform: translateY(-20px); }
  }
  
  .animate-float {
    animation: float 6s ease-in-out infinite;
  }
  
  @media (max-width: 768px) {
    .pricing-grid {
      grid-template-columns: 1fr;
      gap: 1.5rem;
      padding: 1rem;
    }
    
    .pricing-card {
      max-width: 100%;
    }
  }
}

@keyframes gradient-background {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.animate-gradient-background {
  background: linear-gradient(45deg, rgba(0,0,0,0.7), rgba(0,30,60,0.7));
  background-size: 200% 200%;
  animation: gradient-background 15s ease infinite;
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

.glow-text {
  text-shadow: 0 0 20px rgba(255,255,255,0.3);
}

.video-preview {
  transition: transform 0.3s ease-out;
}

.video-preview:hover {
  transform: scale(1.02);
}

.preview-button {
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.2s ease-out;
}

.video-preview:hover .preview-button {
  opacity: 1;
  transform: translateY(0);
}

/* Add this to make Clerk's UserButton always visible */
.cl-userButtonBox {
  opacity: 1 !important;
}

.cl-userButtonTrigger {
  opacity: 1 !important;
}